import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { toast } from 'react-toastify';

import PersonIcon from '@material-ui/icons/Person';

import DJDashboard from './DJDashboard';
import wsClient from '../utils/WebSocketsWrapper';
import { userStatus, setTokens, decodeToken, clearTokens } from '../utils/TokenUtils';
import { UserTypes } from '../Types/Globals';
import { DJContext } from '../contexts/DJContext';

const Login = () => {
  const history = useHistory();
  const { state } = useContext(DJContext);
  const [username, setUser] = useState('Avicii');
  const [password, setPassword] = useState('Skulls2021');
  const [loggedIn, setLoggedIn] = useState(false);
  const [busy, setBusy] = useState(false);
  const [socketLogin, setSocketLogin] = useState(false);

  const setupLogin = () => {
    setLoggedIn(true);

    const {
      djInfo: { username },
      socketPassword,
    } = decodeToken();

    wsClient.addEventListener('socketLoginDJ', (payload) => {
      if (payload.status) {
        setSocketLogin(true);
        wsClient.setUsername(username);
        wsClient.send('loadDJ', {});
      } else {
        clearTokens();
        window.location.reload();
        return;
      }
    });

    wsClient.send('socketLoginDJ', { username, socketPassword });
  };

  useEffect(() => {
    switch (userStatus()) {
      case UserTypes.DJ:
        setupLogin();
        break;
      default:
        wsClient.addEventListener('login', ({ tokens }) => {
          setBusy(false);
          if (tokens) {
            setTokens(tokens.access, tokens.refresh);
            setupLogin();
          } else {
            toast.error('Incorrect credentials!');
          }
        });
    }
  }, []);

  const login = () => {
    setBusy(true);
    wsClient.send('login', { username, password });
  };

  return (
    <>
      {loggedIn ? (
        <>{socketLogin && state.loaded ? <DJDashboard /> : <div>Loading...</div>}</>
      ) : (
        <>
          <LoginContainer>
            <LoginHeading>DJ Login</LoginHeading>
            <PersonIcon />
            <LoginForm>
              <TextInput
                value={username}
                onChange={(e) => setUser(e.target.value)}
                placeholder='John Doe'
                onKeyPress={(e) => {
                  if (busy) return;
                  if (e.key === 'Enter') login();
                }}
              ></TextInput>
              <TextInput
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder='********'
                type='password'
                onKeyPress={(e) => {
                  if (busy) return;
                  if (e.key === 'Enter') login();
                }}
              ></TextInput>
            </LoginForm>
            <Button disabled={busy} onClick={() => login()}>
              Login
            </Button>
          </LoginContainer>
          <HomeScreen>
            <Button onClick={() => history.push('/')}>Back to Home</Button>
          </HomeScreen>
        </>
      )}
    </>
  );
};

export default Login;

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;

  // Center horizontal + vertical
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 250px;
  color: white;
  background: white;

  .MuiSvgIcon-root {
    fill: #1a1a1d;
    align-self: center;
    font-size: 70px;
  }
`;

const LoginHeading = styled.div`
  font-size: 20px;
  font-weight: bold;
  background: #950740;
  text-align: center;
  padding: 15px;
`;

const LoginForm = styled.div`
  padding: 0 10px;
`;

const TextInput = styled.input`
  width: calc(100% - 20px);
  height: 30px;
  font-size: 14px;
  outline: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 2px 10px;
  margin: 10px 0;
`;

const Button = styled.button`
  background: #1a1a1d;
  border: none;
  padding: 15px 30px;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  align-self: center;
  width: 100%;
  color: white;

  :hover {
    background-color: #45a049;
    color: black;
  }

  &:disabled {
    background-color: silver;
    color: dimgray;
    cursor: not-allowed;
  }
`;

const HomeScreen = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;

  button {
    padding: 8px 15px;
    font-size: 12px;
  }
`;
