import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { animated } from 'react-spring';

import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import { RequestContext, RequestActions } from '../contexts/RequestsContext';
import { SongProps } from '../Types/Globals';

type Props = {
  song: SongProps;
};

// TODO: Animate component

function ResultItem({ song: { songID, imgURL, track, artist } }: Props) {
  const { room } = useParams<{ room: string }>();
  const { dispatch } = useContext(RequestContext);

  const addSong = () => {
    dispatch({
      type: RequestActions.RequestSong,
      payload: { room, songID, imgURL, track, artist },
    });
  };

  return (
    <ItemContainer>
      <SongContainer>
        <img src={imgURL} alt='Track' />
        <SongDetails>
          <div>{track}</div>
          <div>{artist}</div>
        </SongDetails>
        <IconButton onClick={addSong} id='select'>
          <AddCircleOutlineIcon fontSize='large' />
        </IconButton>
      </SongContainer>
    </ItemContainer>
  );
}

export default ResultItem;

const ItemContainer = styled(animated.div)`
  width: 100%;
  border-bottom: 4px solid;
  height: 64px;
  position: relative;

  display: flex;
`;

const SongContainer = styled(animated.div)`
  background: #c3073f;
  position: absolute;
  width: 100%;
  height: 64px;

  font-weight: 600;

  display: flex;
  flex-direction: row;

  #select {
    color: black;
  }
`;

const SongDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 5px 10px;
  flex: 1;
`;
