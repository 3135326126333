import { toast } from 'react-toastify';
import { UserTypes } from '../Types/Globals';
import { decodeToken, getUsername, userStatus } from './TokenUtils';

// Create a list of event functions, indexed by its event name
// NOTE: Declared outside function just in case server disconnects
//       and we have to re-add all events.
const events: { [key: string]: ((...args: any[]) => void)[] } = {};
// Messages sent while the server was connecting/unresponsive
const queuedMessages: { event: string; payload: any }[] = [];
class WrappedWS {
  private static instance: WrappedWS | null;
  client!: WebSocket;
  #username = '';

  constructor(url: string) {
    // Only a single instance is allowed
    if (WrappedWS.instance) return WrappedWS.instance;
    WrappedWS.instance = this;

    this.init(url);
  }

  init(url: string) {
    this.client = new WebSocket(url);
    this.#username = getUsername();

    this.client.addEventListener('message', (e) => {
      const { event, payload } = JSON.parse(e.data);
      // Call the event function (if it exists)
      events[event]?.map((cb) => cb(payload));
    });

    /* Redirect all other ws events to any existing event cbs */
    ['close', 'error', 'open', 'ping', 'pong', 'unexpected-response', 'upgrade'].forEach(
      (event) => {
        this.client.addEventListener(event, (...args: any[]) => {
          // Call the event function (if it exists)
          events[event]?.map((cb) => cb(args));
        });
      }
    );
  }

  addEventListener(event: string, cb: (...args: any[]) => void): void {
    if (event in events) {
      events[event].push(cb);
    } else {
      events[event] = [cb];
    }
  }

  send(event: string, payload: any): boolean {
    const serialized = JSON.stringify({ event, payload: { guest: this.#username, ...payload } });
    if (this.client.readyState === 1) {
      this.client.send(serialized);
      return true;
    } else {
      queuedMessages.push({ event, payload });
      return false;
    }
  }

  setUsername(username: string) {
    this.#username = username;
  }

  getState(): number {
    return this.client.readyState;
  }

  dequeueMessages(): void {
    if (queuedMessages.length) {
      // NOTE: The following should not be needed, but just in case of server errors
      //       and to stop infinite looping
      let trialNum = 0;
      while (queuedMessages.length) {
        if (trialNum === 5) break;
        const { event, payload } = queuedMessages[0];

        if (wsClient.send(event, payload)) {
          queuedMessages.shift();
          trialNum = 0;
        } else {
          trialNum += 1;
        }
      }
    }
  }
}

let wsClient: WrappedWS;

(() => {
  const url = process.env.REACT_APP_API_WebSockets!;
  let reconnection = 0;
  wsClient = new WrappedWS(url);

  wsClient.addEventListener('open', () => {
    if (reconnection) {
      reconnection = 0;
      toast.info('Successfully reconnected to server!');
    }

    // Must reauthenticate with server (if necessary) by dequeuing.
    const status = userStatus();
    if (status === UserTypes.NULL) {
      wsClient.dequeueMessages();
    } else if (status === UserTypes.GUEST) {
      wsClient.send('loadGuest', {});
    } else if (status === UserTypes.DJ) {
      const {
        djInfo: { username },
        socketPassword,
      } = decodeToken();
      wsClient.send('socketLoginDJ', { username, socketPassword });
    }
  });

  wsClient.addEventListener('socketLoginDJ', (payload) => {
    if (payload.status) {
      wsClient.dequeueMessages();
      wsClient.send('loadDJ', {});
    }
  });

  wsClient.addEventListener('loadGuest', () => {
    wsClient.dequeueMessages();
  });

  wsClient.addEventListener('close', () => {
    // Attempt to reconnect 5 times every 5 seconds
    if (reconnection === 0) {
      toast.error('Connection lost. Trying to reconnect');
    } else if (reconnection === 5) {
      toast.error('Could not reconnect to the server. Please try again later.');
      return;
    }

    reconnection += 1;
    setTimeout(() => {
      wsClient.init(url);
    }, 5000);
  });
})();

export default wsClient;
