export enum SearchStates {
  INITIAL,
  SEARCHING,
  COMPLETED,
}

export enum SongStates {
  REQUESTED = 'REQUESTED',
  ACCEPTED = 'ACCEPTED',
  PLAYING = 'PLAYING',
  REJECTED = 'REJECTED',
}

export enum UserTypes {
  NULL = 'NULL',
  GUEST = 'GUEST',
  DJ = 'DJ',
  ADMIN = 'ADMIN',
}

export type SongProps = {
  status: string;
  songID: string;
  imgURL: string;
  track: string;
  artist: string;
  _id?: string;
  room?: string;
};
