import React, { useContext } from 'react';
import styled from 'styled-components/macro';

import { makeStyles } from '@material-ui/core/styles';
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from '@material-ui/data-grid';
import Tooltip from '@material-ui/core/Tooltip';

import IconButton from '@material-ui/core/IconButton';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

import NoDataOverlay from '../NoDataOverlay';
import { DJActions, DJContext } from '../../contexts/DJContext';
import { SongStates } from '../../Types/Globals';

const gridStyles = makeStyles(() => ({
  root: {
    border: 0,
    color: 'rgba(0,0,0,.85)',
    background: '#fafafa',

    '& .MuiDataGrid-iconSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-colCell, .MuiDataGrid-cell': {
      borderRight: '1px solid #f0f0f0',
    },
    '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
      borderBottom: '1px solid #f0f0f0',
    },
    '& .MuiPaginationItem-root': {
      borderRadius: 0,
    },
  },
}));

function Requests() {
  const { state, dispatch } = useContext(DJContext);
  const classes = gridStyles();

  return (
    <TableContainer>
      {state.parties[state.currentParty].rooms.length > 0 ? (
        state.parties[state.currentParty].rooms.map((room: any, i) => (
          <React.Fragment key={`frag${i}`}>
            <RoomTitle key={`title${i}`}>{room.name}</RoomTitle>
            <DataGrid
              key={`grid${i}`}
              components={{
                NoRowsOverlay: NoDataOverlay,
                Toolbar: GridToolbar,
              }}
              rows={room.requests.map((row: any) => ({
                ...row,
                blah: 'yo',
              }))}
              columns={
                [
                  { field: 'guests', headerName: 'Guest(s)', flex: 1 },
                  { field: 'track', headerName: 'Track', flex: 3 },
                  { field: 'artist', headerName: 'Artist', flex: 1 },
                  {
                    field: 'createdAt',
                    headerName: 'Time',
                    flex: 1,
                    type: 'dateTime',
                  },
                  // TODO: If a user re-requests, this number goes up:
                  // Give the DJ a breakdown of the request count of this song by user to track misuse and spamming rerequests
                  // {
                  //   field: 'requestNum',
                  //   headerName: 'Requests',
                  //   flex: 1,
                  //   type: 'number',
                  // },
                  {
                    field: 'id',
                    headerName: 'Actions',
                    flex: 1,
                    headerAlign: 'center',
                    renderCell: (params: GridCellParams) => (
                      <div style={{ display: 'flex', width: '100%' }}>
                        {/* TODO: Fix this later: */}
                        {params.getValue('status')}
                        <span style={{ flex: '1', textAlign: 'center' }}>
                          <IconButton
                            size='small'
                            onClick={() => {
                              dispatch({
                                type: DJActions.ChangeStatus,
                                payload: {
                                  status: SongStates.ACCEPTED,
                                  request: params.value?.toString(),
                                  guests: params.getValue('guests') as string[],
                                  songID: params.getValue('songID') as string,
                                  room: room._id,
                                },
                              });
                            }}
                          >
                            <Tooltip title='Accept'>
                              <CheckCircleIcon />
                            </Tooltip>
                          </IconButton>
                        </span>
                        <span style={{ flex: '1', textAlign: 'center' }}>
                          <IconButton
                            size='small'
                            onClick={() => {
                              dispatch({
                                type: DJActions.ChangeStatus,
                                payload: {
                                  status: SongStates.REJECTED,
                                  request: params.value?.toString(),
                                  guests: params.getValue('guests') as string[],
                                  songID: params.getValue('songID') as string,
                                  room: room._id,
                                },
                              });
                            }}
                            style={{ flex: '1' }}
                          >
                            <Tooltip title='Reject'>
                              <RemoveCircleIcon />
                            </Tooltip>
                          </IconButton>
                        </span>
                      </div>
                    ),
                  },
                ] as GridColDef[]
              }
              pageSize={10}
              className={classes.root}
            />
          </React.Fragment>
        ))
      ) : (
        // TODO: Add an overlay here
        <div>No Rooms</div>
      )}
    </TableContainer>
  );
}

export default Requests;

const TableContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  > div:nth-child(even) {
    min-height: calc(100vh - 64px - 48px - 20px);
    margin: 10px;
  }
`;

const RoomTitle = styled.div`
  margin: 0 10px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 20px;
  font-weight: 800;
`;
