import React, { useEffect, useReducer, createContext, Dispatch } from 'react';
import cloneDeep from 'lodash/cloneDeep';

import wsClient from '../utils/WebSocketsWrapper';
import { SongStates } from '../Types/Globals';
import { GuestType, PartyType } from '../Types/DJ';

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export enum DJActions {
  InitData = 'INIT_DATA',
  ChangeStatus = 'CHANGE_STATUS',
  ChangeParty = 'CHANGE_PARTY',
  NewRequest = 'NEW_REQUEST',
  CancelRequest = 'CANCEL_REQUEST',
  ReRequest = 'RE-REQUEST',
  BanGuest = 'BAN_GUEST',
  NewGuest = 'NEW_GUEST',
}

export interface IState {
  loaded: boolean;
  parties: PartyType[];
  currentParty: number;
}

interface DispatchActions {
  [DJActions.InitData]: {
    // TODO: Adjust the following type
    payload: any;
  };
  [DJActions.ChangeParty]: {
    partyIdx: number;
  };
  [DJActions.ChangeStatus]: {
    status: SongStates;
    request: string | undefined;
    guests: string[];
    songID: string;
    room: string;
  };
  [DJActions.NewRequest]: {
    // TODO: Adjust the following type
    payload: any;
  };
  [DJActions.CancelRequest]: {
    // TODO: Adjust the following type
    payload: any;
  };
  [DJActions.ReRequest]: {
    // TODO: Adjust the following type
    payload: any;
  };
  [DJActions.BanGuest]: {
    guest: string;
    banned: boolean;
  };
  [DJActions.NewGuest]: {
    guest: GuestType;
    partyCode: string;
  };
}

type Actions = ActionMap<DispatchActions>[keyof ActionMap<DispatchActions>];

const getRequestInfo = (
  roomID: string,
  songID: string,
  state: IState
): { roomIdx: number; requestIdx: number } | null => {
  const roomIdx = state.parties[state.currentParty].rooms.findIndex((room: any) => {
    return room._id === roomID;
  });
  if (roomIdx === -1) return null;

  let requestIdx = -1;
  for (const [i, request] of state.parties[state.currentParty].rooms[roomIdx].requests.entries()) {
    if (request.songID === songID) {
      requestIdx = i;
      break;
    }
  }
  return { roomIdx, requestIdx };
};

const reducer = (state: IState, { type, payload }: { type: string; payload: any }) => {
  switch (type) {
    case DJActions.InitData: {
      return payload;
    }
    case DJActions.ChangeParty: {
      // TODO: Fix this... the problem could be with the material grid as well
      return { ...cloneDeep(state), currentParty: payload.partyIdx };
    }
    case DJActions.ChangeStatus: {
      const res = getRequestInfo(payload.room, payload.songID, state);
      if (res === null) {
        return state;
      }

      const { roomIdx, requestIdx } = res;

      const modified = cloneDeep(state.parties);
      modified[state.currentParty].rooms[roomIdx].requests[requestIdx].status = payload.status;

      wsClient.send('queueStatus', payload);
      return { ...state, parties: modified };
    }
    case DJActions.NewRequest: {
      const res = getRequestInfo(payload.room, payload.songID, state);
      if (res === null) {
        return state;
      }

      const { roomIdx, requestIdx } = res;

      const modified = cloneDeep(state.parties);
      if (requestIdx !== -1) {
        modified[state.currentParty].rooms[roomIdx].requests[requestIdx].guests.push(payload.guest);
      } else {
        modified[state.currentParty].rooms[roomIdx].requests.push(payload);
      }

      return { ...state, parties: modified };
    }
    case DJActions.CancelRequest: {
      const res = getRequestInfo(payload.room, payload.songID, state);
      if (res === null) {
        return state;
      }

      const { roomIdx, requestIdx } = res;

      const modified = cloneDeep(state.parties);

      if (requestIdx !== -1) {
        let guestCount = 0; // See if multiple guests...
        modified[state.currentParty].rooms[roomIdx].requests[requestIdx].guests = modified[
          state.currentParty
        ].rooms[roomIdx].requests[requestIdx].guests.filter((guest: string) => {
          if (guest !== payload.guest) {
            guestCount++;
            return true;
          }
          return false;
        });

        if (guestCount === 0) {
          modified[state.currentParty].rooms[roomIdx].requests.splice(requestIdx, 1);
        }
      } else {
        console.error('DJ not in sync, or invalid request id');
        // Not possible, unless DJ is out of sync...
        return state;
      }

      return { ...state, parties: modified };
    }
    case DJActions.ReRequest: {
      console.log('Got a re-request:');
      console.log(payload);

      return state;
    }
    case DJActions.BanGuest: {
      const modified = cloneDeep(state.parties);
      for (const [i, guest] of modified[state.currentParty].guests.entries()) {
        if (guest.guest === payload.guest) {
          modified[state.currentParty].guests[i].banned = payload.banned;
          break;
        }
      }

      wsClient.send('banGuest', payload);

      return { ...state, parties: modified };
    }
    case DJActions.NewGuest: {
      const modified = cloneDeep(state.parties);
      for (const [i, party] of modified.entries()) {
        if (party.code === payload.partyCode) {
          modified[i].guests.push(payload.guest);
          break;
        }
      }

      wsClient.send('banGuest', payload);

      return { ...state, parties: modified };
    }
    default: {
      throw new Error(`Unknown action: ${type}`);
    }
  }
};

interface IContextProps {
  state: IState;
  dispatch: Dispatch<Actions>;
}

export const DJContext = createContext({} as IContextProps);

export const DJProvider = ({ children }: { children: any }) => {
  const [state, dispatch] = useReducer(reducer, { parties: [], currentParty: 0, loaded: false });

  useEffect(() => {
    wsClient.addEventListener('loadDJ', ({ data }) => {
      dispatch({
        type: DJActions.InitData,
        payload: { currentParty: 0, parties: data.parties, loaded: !!data },
      });
    });
    wsClient.addEventListener('request', (payload) => {
      dispatch({ type: DJActions.NewRequest, payload });
    });
    wsClient.addEventListener('requestCancel', (payload) => {
      dispatch({ type: DJActions.CancelRequest, payload });
    });
    wsClient.addEventListener('rerequest', (payload) => {
      dispatch({ type: DJActions.ReRequest, payload });
    });
    wsClient.addEventListener('newGuest', (payload) => {
      dispatch({ type: DJActions.NewGuest, payload });
    });
  }, []);

  return <DJContext.Provider value={{ state, dispatch }}>{children}</DJContext.Provider>;
};
