import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components/macro';

import wsClient from '../utils/WebSocketsWrapper';
import { getItem, clearItem } from '../utils/StorageUtils';
import { userStatus, decodeToken, setTokens, clearTokens } from '../utils/TokenUtils';
import { UserTypes } from '../Types/Globals';

const Welcome = () => {
  const history = useHistory();
  const [username, setLocalUsername] = useState('');
  const [numUsers, setNum] = useState(0);
  const [busy, setBusy] = useState(false);
  const [partyName, setName] = useState('');
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    switch (userStatus()) {
      case UserTypes.GUEST:
        const { party } = decodeToken().guestInfo;
        if (party.rooms.length === 0) {
          toast.error('No rooms available!');
          clearTokens();
          setTimeout(() => {
            history.replace('/');
          }, 3000);
        } else {
          // Go to the first room
          history.replace(`/live/${party.rooms[0]}`);
        }
        break;
      case UserTypes.DJ:
        history.push('/portal');
        break;
      case UserTypes.NULL:
      default:
        // TODO: Make sure to allow the user to clear this in the welcome page!
        //       Maybe he wants a different party now...
        const { name } = getItem('jukeboxParty') || {};
        if (!name) {
          history.replace('/');
          return;
        } else {
          setName(name);
          wsClient.send('welcome', {});
        }

        wsClient.addEventListener('welcome', (payload) => {
          setLoaded(true);
          setNum(payload.numUsers);
          setLocalUsername(payload.username);
        });

        wsClient.addEventListener('guestsUpdate', (payload) => {
          setNum(payload.numUsers);
        });

        wsClient.addEventListener('setUsername', ({ available, tokens }) => {
          setBusy(false);
          if (!available) {
            toast.error('Name unavailable!');
          } else {
            if (tokens) {
              setTokens(tokens.access, tokens.refresh);
            } else {
              toast.error('No such party exists!');
            }
            // Clear the storage. Data will be in token (if valid party)
            clearItem('jukeboxParty');
            window.location.reload();
          }
        });
    }
  }, [history]);

  const login = () => {
    setBusy(true);
    const importParty = getItem('jukeboxParty');
    if (importParty) {
      wsClient.send('setUsername', { username, partyCode: importParty.code });
    } else {
      history.replace('/');
    }
  };

  return (
    <>
      {loaded ? (
        <WelcomeContainer>
          <div>
            <PartyHeading>{partyName}</PartyHeading>
            There
            {(function () {
              if (!numUsers) {
                return <> are no users </>;
              } else if (numUsers === 1) {
                return <> is 1 user </>;
              }
              return <> are {numUsers} users </>;
            })()}
            online now! <br />
            Enter a username to continue
          </div>
          <TextInput
            value={username}
            onChange={(e) => setLocalUsername(e.target.value)}
            placeholder='John Doe'
            onKeyPress={(e) => {
              if (busy) return;
              if (e.key === 'Enter') login();
            }}
          ></TextInput>
          <Button disabled={busy || username.length === 0} onClick={login}>
            Set Username
          </Button>
        </WelcomeContainer>
      ) : (
        <>Loading...</>
      )}
      <UserLogin>
        <Button onClick={() => history.push('/portal')}>Portal</Button>
      </UserLogin>
    </>
  );
};

export default Welcome;

const WelcomeContainer = styled.div`
  display: flex;
  flex-direction: column;

  // Center horizontal + vertical
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 250px;
  color: white;
`;

const PartyHeading = styled.div`
  font-size: 24px;
  color: black;
  font-weight: 800;
  text-align: center;
  padding: 20px;
`;

const TextInput = styled.input`
  height: 30px;
  font-size: 14px;
  outline: none;
  border: none;
  padding: 2px 10px;
  margin: 10px 0;
`;

const Button = styled.button`
  background: #c3073f;
  border: none;
  padding: 15px 30px;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  align-self: center;
  width: 100%;

  &:disabled {
    background-color: silver;
    color: dimgray;
    cursor: not-allowed;
  }
`;

const UserLogin = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;

  button {
    padding: 8px 15px;
    font-size: 12px;
  }
`;
