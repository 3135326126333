import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components/macro';

import SearchResults from './SearchResults';
import { SearchStates } from '../Types/Globals';

import wsClient from '../utils/WebSocketsWrapper';

const debouncer = (fn: Function, delay: number) => {
  let timeout: NodeJS.Timeout;
  return (...args: any[]) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      fn(...args);
    }, delay);
  };
};

function Search() {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState<any[]>([]);
  const [searchState, setBusy] = useState(SearchStates.INITIAL);

  useEffect(() => {
    wsClient.addEventListener('querySearch', ({ results }) => {
      setBusy(SearchStates.COMPLETED);
      setResults(results);
    });
  }, []);

  const delayedQuery = useCallback(
    debouncer((query: string) => {
      querySearch(query);
    }, 500),
    []
  );

  const handleChange = (val: string) => {
    setQuery(val);
    delayedQuery(val);
  };

  const querySearch = (query: string) => {
    setBusy(SearchStates.SEARCHING);
    wsClient.send('querySearch', { query });
  };

  return (
    <SearchContainer>
      <SearchInput>
        <TextInput
          value={query}
          onChange={(e) => handleChange(e.target.value)}
          placeholder='Search'
        />
      </SearchInput>
      <SearchResults searchState={searchState} data={results} />
    </SearchContainer>
  );
}

export default Search;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100% - 40px);
  margin: auto 20px;
`;

const SearchInput = styled.div`
  display: flex;
  margin: 20px 0 0;
`;

const TextInput = styled.input`
  min-width: 20vw;
  font-size: 18px;
  outline: none;
  border: none;
  padding: 15px 10px;
  flex: 1;
`;
