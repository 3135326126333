import React, { useContext, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SearchIcon from '@material-ui/icons/Search';
import QueueMusicIcon from '@material-ui/icons/QueueMusic';
import PersonPinIcon from '@material-ui/icons/PersonPin';

import Search from '../components/Search';
import UserQueue from '../components/UserQueue';
import UserProfile from '../views/UserProfile';
import { RequestContext } from '../contexts/RequestsContext';

const useStyles = makeStyles({
  root: {
    height: 'calc(100vh - 48px)',
    boxShadow: 'none',
    background: '#1a1a1d',
    overflow: 'auto',
  },

  tabs: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    background: 'white',
  },
});

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div hidden={value !== index} {...other}>
      {value === index && <>{children}</>}
    </div>
  );
}

function UserDashboard() {
  const { state } = useContext(RequestContext);
  const classes = useStyles();
  const [tab, setTab] = useState(1);

  const tabChange = (e: any, newValue: any) => {
    setTab(newValue);
  };

  return (
    <>
      {state.loaded ? (
        <>
          <Paper square className={classes.root}>
            <TabPanel value={tab} index={0}>
              <Search />
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <UserQueue />
            </TabPanel>
            <TabPanel value={tab} index={2}>
              <UserProfile />
            </TabPanel>

            <Tabs
              value={tab}
              onChange={tabChange}
              variant='fullWidth'
              indicatorColor='primary'
              textColor='primary'
              className={classes.tabs}
            >
              <Tab icon={<SearchIcon />} />
              <Tab icon={<QueueMusicIcon />} />
              <Tab icon={<PersonPinIcon />} />
            </Tabs>
          </Paper>
        </>
      ) : (
        <>Loading...</>
      )}
    </>
  );
}

export default UserDashboard;
