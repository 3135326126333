import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode';
import styled from 'styled-components/macro';

import { withStyles, fade, makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import InputBase from '@material-ui/core/InputBase';
import FormControl from '@material-ui/core/FormControl';
import { decodeToken } from '../utils/TokenUtils';

/*
  TODO:
  - Allow username change?
  - Changing rooms
*/

const useStyles = makeStyles({
  labels: {
    color: 'white',
  },
});

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: 'white',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: 'auto',
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}))(InputBase);

const UserProfile = () => {
  const classes = useStyles();
  const [username, setUsername] = useState('sdfdsf');

  useEffect(() => {
    const {
      guest,
      party: { code },
    } = decodeToken().guestInfo;
    setUsername(guest);

    // Must go to: http://localhost:3000/join/:{code}
    QRCode.toCanvas(
      `${window.location.protocol}//${window.location.host}/join/${code}`,
      { errorCorrectionLevel: 'M', width: 300 },
      function (err, canvas) {
        if (err) throw err;

        const container = document.getElementById('container');
        if (container) {
          container.appendChild(canvas);
        } else {
          // TODO: Log failure to server as well
          console.error('Unable to generate QR code');
        }
      }
    );
  }, []);

  return (
    <ProfileContainer id='container'>
      <FormControl>
        <InputLabel shrink className={classes.labels}>
          Username
        </InputLabel>
        {/* TODO: Allow changing of username later */}
        <BootstrapInput defaultValue='John Doe' disabled value={username} />
      </FormControl>
      <div>Invite friends to the party with the following QR code:</div>
    </ProfileContainer>
  );
};

export default UserProfile;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  color: white;
  // color: #c3073f;

  div {
    margin-bottom: 10px;
  }

  canvas {
    align-self: center;
  }
`;
