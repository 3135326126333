import jwtDecode from 'jwt-decode';
import { getItem, setItem, clearItem } from './StorageUtils';
import { UserTypes } from '../Types/Globals';

const ACCESS_TOKEN = 'jukeboxAcess';
const REFRESH_TOKEN = 'jukeboxRefresh';

export const userStatus = (): UserTypes => {
  const token = getToken();
  if (token) {
    const data: any = jwtDecode(token);
    if ('guestInfo' in data) {
      return UserTypes.GUEST;
    }
    if ('djInfo' in data) {
      return UserTypes.DJ;
    }
    if ('adminInfo' in data) {
      return UserTypes.ADMIN;
    }
  }
  return UserTypes.NULL;
};

export const getToken = (token = ACCESS_TOKEN) => {
  return getItem(token);
};

export const getRefreshToken = () => {
  return getItem(REFRESH_TOKEN);
};

export const setTokens = (token: any, refreshToken?: any) => {
  if (token) setItem(ACCESS_TOKEN, token);
  if (refreshToken) setItem(REFRESH_TOKEN, refreshToken);
};

export const clearTokens = () => {
  clearItem(ACCESS_TOKEN);
  clearItem(REFRESH_TOKEN);
};

export const decodeToken = (): any => {
  try {
    const token = getToken();
    if (token) {
      return jwtDecode(token);
    }
    return null;
  } catch (err) {
    // TODO: Send error to server
    console.error(err);
    return null;
  }
};

export const getUsername = (): string => {
  const token = getToken();
  if (token) {
    const data: any = jwtDecode(token);
    return data.guestInfo?.guest || data.djInfo?.username || data.adminInfo?.username;
  }
  return '';
};
