import React, { useState } from 'react';

import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import QueueMusicIcon from '@material-ui/icons/QueueMusic';
import PeopleIcon from '@material-ui/icons/People';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import Requests from '../components/dj/Requests';
import Guests from '../components/dj/Guests';
import Profile from '../components/dj/Profile';

import { clearTokens } from '../utils/TokenUtils';

import Button from '@material-ui/core/Button';

/*
  TODO:
  - It will have an interface of:
    - Showing ALL requests
    - Filter requests by room, user
  - It will be connected to a secondary app that is installed on the DJ's system
    - This app will automatically download the track with that downloader's program
      - (Only if it doesn't exist)
    - Then it will try and queue it somewhere in the Serato DJ Pro software
  - It will allow the DJ to persist the data after the party, in which case it goes into MongoDB
  - The settings page will allow the DJ to enable/disable getting desktop notifications
*/

const drawerWidth = 240;

const DJStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#1A1A1D',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: '#950740',
    color: 'white',
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
    backgroundColor: '#950740',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function DJDashboard() {
  const classes = DJStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState('requests');

  const handleChange = (tab: string) => {
    if (tab === 'logout') {
      clearTokens();
      window.location.reload();
    } else {
      setTab(tab);
    }
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const test = () => {
    console.log(Notification.permission);
    if ('Notification' in window) {
      if (Notification.permission === 'granted') {
        new Notification('Yo');
      } else if (Notification.permission !== 'denied') {
        Notification.requestPermission().then((res) => {
          if (res === 'granted') {
            new Notification('Yo');
          }
        });
      }
    }
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position='fixed'
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color='inherit'
            onClick={handleDrawerOpen}
            edge='start'
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <h2>DJ Dashboard</h2>
        </Toolbar>
      </AppBar>
      <Drawer
        variant='permanent'
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListItem button onClick={() => handleChange('requests')}>
            <ListItemIcon>
              <QueueMusicIcon />
            </ListItemIcon>
            <ListItemText primary='Requests' />
          </ListItem>
          <ListItem button onClick={() => handleChange('guests')}>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary='Guests' />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button onClick={() => handleChange('profile')}>
            <ListItemIcon>
              <PersonPinIcon />
            </ListItemIcon>
            <ListItemText primary='Profile' />
          </ListItem>
          <ListItem button onClick={() => handleChange('settings')}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary='Settings' />
          </ListItem>
          <ListItem button onClick={() => handleChange('logout')}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary='Logout' />
          </ListItem>
        </List>
      </Drawer>

      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Button variant='contained' color='primary' onClick={test}>
          Test Notifications
        </Button>
        <div hidden={tab !== 'requests'}>
          <Requests />
        </div>
        <div hidden={tab !== 'guests'}>
          <Guests />
        </div>
        <div hidden={tab !== 'profile'}>
          <Profile />
        </div>
        <div hidden={tab !== 'settings'}>App Settings</div>
      </main>
    </div>
  );
}

export default DJDashboard;
