import React, { useEffect, useReducer, createContext, Dispatch } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import cloneDeep from 'lodash/cloneDeep';

import wsClient from '../utils/WebSocketsWrapper';
import { decodeToken, clearTokens, userStatus } from '../utils/TokenUtils';
import { SongProps, UserTypes } from '../Types/Globals';

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export enum RequestActions {
  InitData = 'INIT_DATA',
  ChangeRoom = 'CHANGE_ROOM',
  RequestSong = 'REQUEST_SONG',
  RemoveSong = 'REMOVE_SONG',
  RerequestSong = 'RE_REQUEST_SONG',
  ChangeStatus = 'CHANGE_STATUS',
}

interface IState {
  rooms: { [index: string]: SongProps[] };
  loaded: boolean;
}

interface DispatchActions {
  [RequestActions.InitData]: {
    // TODO: Adjust the following type
    requests: IState[];
  };
  [RequestActions.RequestSong]: {
    room: string;
    songID: string;
    imgURL: string;
    track: string;
    artist: string;
  };
  [RequestActions.RemoveSong]: {
    room: string;
    songID: string;
    track: string;
    artist: string;
  };
  [RequestActions.RerequestSong]: {
    room: string;
    songID: string;
    track: string;
    artist: string;
  };
  [RequestActions.ChangeStatus]: {
    payload: any;
    status: string;
    room: string;
  };
}

type Actions = ActionMap<DispatchActions>[keyof ActionMap<DispatchActions>];

const reducer = (state: any, { type, payload }: { type: string; payload: any }) => {
  switch (type) {
    case RequestActions.InitData: {
      const initState: IState = { rooms: {}, loaded: true };

      // Guest no longer exists (or never existed)
      if (!payload) {
        clearTokens();
        window.location.reload();
        return;
      }
      if (!('requests' in payload)) return initState;

      decodeToken().guestInfo.party.rooms.forEach((room: string) => {
        if (room in payload.requests) {
          initState.rooms[room] = [...payload.requests[room]];
        }
      });

      return initState;
    }
    case RequestActions.RequestSong: {
      const modified = { ...state.rooms };
      const { room, ...rest } = payload;
      rest.status = 'REQUESTED';

      const res = modified[room]?.find((x: any) => x.songID === payload.songID);
      if (!res) {
        if (room in modified) {
          modified[room].push(rest);
        } else {
          modified[room] = [rest];
        }

        wsClient.send('request', payload);
        toast.info(`${payload.track} by ${payload.artist} requested!`);

        return { ...state, rooms: modified };
      } else {
        toast.error('Song already requested!');
        return state;
      }
    }
    case RequestActions.RemoveSong: {
      const { room, songID } = payload;
      if (!(room in state.rooms)) return state; // Verify room is valid...

      const modified = state.rooms[room].filter((x: any) => x['songID'] !== songID);

      wsClient.send('requestCancel', payload);
      toast.info(`${payload.track} by ${payload.artist} cancelled!`);

      return { ...state, rooms: { ...state.rooms, [room]: modified } };
    }
    case RequestActions.RerequestSong: {
      wsClient.send('rerequest', payload);
      toast.info(`Re-requested ${payload.track} by ${payload.artist}!`);

      return state;
    }
    case RequestActions.ChangeStatus: {
      const modified = cloneDeep(state.rooms);
      for (const [i, request] of modified[payload.room].entries()) {
        if (request._id === payload.request) {
          toast.info(`${request.track} by ${request.artist} just got ${payload.status}!`);
          modified[payload.room][i].status = payload.status;
          break;
        }
      }

      return { ...state, rooms: modified };
    }
    default: {
      throw new Error(`Unknown action: ${type}`);
    }
  }
};

interface IContextProps {
  state: IState;
  dispatch: Dispatch<Actions>;
}

export const RequestContext = createContext({} as IContextProps);

export const RequestProvider = ({ children }: { children: any }) => {
  // const [state, dispatch] = useReducer(
  //   React.useCallback((state, action) => reducer(state, action), []),
  //   { rooms: {}, loaded: false }
  // );
  const [state, dispatch] = useReducer(reducer, { rooms: {}, loaded: false });
  const history = useHistory();

  useEffect(() => {
    if (userStatus() === UserTypes.GUEST) {
      wsClient.addEventListener('loadGuest', ({ data }) => {
        dispatch({ type: RequestActions.InitData, payload: data });
      });
      wsClient.addEventListener('queueStatus', (data) => {
        dispatch({ type: RequestActions.ChangeStatus, payload: data });
      });
      wsClient.send('loadGuest', {});
    } else {
      clearTokens();
      history.replace('/');
    }
  }, [history]);

  return <RequestContext.Provider value={{ state, dispatch }}>{children}</RequestContext.Provider>;
};
