import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';

import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { DJActions, DJContext, IState } from '../../contexts/DJContext';
import wsClient from '../../utils/WebSocketsWrapper';
import { clearTokens } from '../../utils/TokenUtils';
import { GuestType } from '../../Types/DJ';

const accordionStyles = makeStyles((theme) => ({
  root: {
    width: '100%',

    '& .MuiTableContainer-root': {
      marginBottom: '16px',
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

function Guests() {
  const { state } = useContext(DJContext);
  const classes = accordionStyles();

  return (
    <div className={classes.root}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Guest</TableCell>
              <TableCell align='center'># of Requests</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.parties[state.currentParty].guests.map((guest) => (
              <Row key={guest._id} row={guest} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* TODO: */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} id='panel1a-header'>
          <Typography className={classes.heading}>Banned Guests</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Guest</TableCell>
                  <TableCell align='right'>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {data.map((row) => (
                      <TableRow key={row.guest}>
                        <TableCell component='th' scope='row'>
                          {row.guest}
                        </TableCell>
                        <TableCell align='right'>
                          <Button variant='contained' color='primary'>
                            Un-ban
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))} */}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} id='panel2a-header'>
          <Typography className={classes.heading}>Idle Guests</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Guest</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {data.map((row) => (
                      <TableRow key={row.guest}>
                        <TableCell component='th' scope='row'>
                          {row.guest}
                        </TableCell>
                      </TableRow>
                    ))} */}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default Guests;

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

type RowProps = { row: any };

const countRequests = (requestsList: { [index: string]: string[] }): number => {
  let res = 0;

  if (requestsList) {
    Object.values(requestsList).forEach((requests) => {
      res += requests.length;
    });
  }
  return res;
};

const getRoom = (state: IState, roomID: string): string => {
  for (const room of state.parties[state.currentParty].rooms) {
    if (room._id === roomID) return room.name;
  }
  return 'Unknown';
};

function Row({ row }: RowProps) {
  const { state, dispatch } = useContext(DJContext);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState<GuestType>();
  const [loaded, setLoaded] = useState(false);
  const classes = useRowStyles();

  const onOpen = () => {
    if (!open) {
      setOpen(true);
      // NOTE: We can check for the existence of data instead of sending a req
      //       But in the interest of keeping fresh data:
      // NOTE: We can also listen in for guest changes on requests, cancellations, etc
      //       But that is for another day
      wsClient.send('getGuest', { guest: row.guest });
      if (!data) {
        wsClient.addEventListener(`getGuest${row.guest}`, (payload) => {
          if (payload.error) {
            // Not authorized
            toast.error(payload.error);
            setTimeout(() => {
              clearTokens();
              window.location.reload();
            }, 3000);
          } else {
            setLoaded(true);
            setData(payload);
          }
        });
      }
    } else {
      setOpen(false);
    }
  };

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton size='small' onClick={onOpen}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component='th' scope='row'>
          {row.guest}
        </TableCell>
        <TableCell align='center'>{countRequests(row.requests)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            {loaded ? (
              <Box margin={1}>
                <Typography
                  variant='h6'
                  gutterBottom
                  component='div'
                  style={{ display: 'flex', position: 'relative' }}
                >
                  Requests
                  <Button
                    variant='contained'
                    color='secondary'
                    style={{ position: 'absolute', right: 0 }}
                    onClick={() =>
                      dispatch({
                        type: DJActions.BanGuest,
                        payload: { banned: !data?.banned, guest: row.guest },
                      })
                    }
                  >
                    {row.banned ? 'Un-ban' : 'Ban'}
                  </Button>
                </Typography>
                <Table size='small'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Room</TableCell>
                      <TableCell>Track</TableCell>
                      <TableCell>Artist</TableCell>
                      <TableCell>Time</TableCell>
                      <TableCell>Re-requests</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!!data?.requests &&
                      Object.entries(data?.requests).map(([room, requests]) => {
                        return requests.map((request) => (
                          <TableRow key={request._id}>
                            <TableCell component='th' scope='row'>
                              {getRoom(state, room)}
                            </TableCell>
                            <TableCell>{request.track}</TableCell>
                            <TableCell>{request.artist}</TableCell>
                            <TableCell>{request.createdAt}</TableCell>
                            <TableCell>0</TableCell>
                            <TableCell>{request.status}</TableCell>
                          </TableRow>
                        ));
                      })}
                  </TableBody>
                </Table>
              </Box>
            ) : (
              <>Loading...</>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
