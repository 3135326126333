import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RequestProvider } from './contexts/RequestsContext';
import { DJProvider } from './contexts/DJContext';
import Home from './views/Home';
import Login from './views/Login';
import Welcome from './views/Welcome';
import UserDashboard from './views/UserDashboard';

import { clearTokens } from './utils/TokenUtils';
import wsClient from './utils/WebSocketsWrapper';
import { setItem } from './utils/StorageUtils';

const UnknownPage = () => {
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      history.replace('/');
    }, 3000);
  }, [history]);

  return (
    <div style={{ height: '100vh', display: 'flex' }}>
      <span style={{ margin: 'auto' }}>404 - Not found :(</span>
    </div>
  );
};

const PartyFromURL = () => {
  const history = useHistory();
  const { partyCode } = useParams<{ partyCode: string }>();

  useEffect(() => {
    if (!partyCode) {
      history.replace('/');
    } else {
      wsClient.addEventListener('findParty', ({ party }) => {
        if (party === null) {
          toast.error('No such party exists!');
          setTimeout(() => {
            history.replace('/');
          }, 3000);
        } else {
          setItem('jukeboxParty', party);
          history.push('/live');
        }
      });
      wsClient.send('findParty', { partyCode });
    }
  }, [history, partyCode]);

  return (
    <div style={{ height: '100vh', display: 'flex' }}>
      <span style={{ margin: 'auto' }}>Finding party...</span>
    </div>
  );
};

// const children = [
//   <>
//     <Route path='/live' key='live1'>
//       <Welcome key='welcome2' />
//     </Route>
//     <Route path='/portal' component={Login} key='login3' />
//     <Route exact path='/' component={Home} key='home4' />
//     <Route component={UnknownPage} key='unknown5' />
//   </>,
// ];

// const AuthorizedRoutes = () => {
//   useEffect(() => {
//     const status = userStatus();
//     console.log(status);
//   }, []);

//   switch (userStatus()) {
//     case UserTypes.GUEST:
//       children.push(
//         <Route path='/live/:room'>
//           <RequestProvider>
//             <UserDashboard />
//           </RequestProvider>
//         </Route>
//       );
//       return <>{children}</>;
//     case UserTypes.DJ:
//       return (
//         <Route path='/dj'>
//           <RequestProvider>
//             <DJDashboard />
//           </RequestProvider>
//         </Route>
//       );
//     case UserTypes.NULL:
//     default:
//       return <>{children}</>;
//   }
// };

function App() {
  useEffect(() => {
    wsClient.addEventListener('djLeave', ({ message }) => {
      toast.error(message);
    });
    wsClient.addEventListener('djMessage', ({ message, level, banned }) => {
      if (level === 'HIGH') {
        toast.error(message);
        if (banned) {
          setTimeout(() => {
            window.location.reload();
            clearTokens();
          }, 5000);
        }
      } else {
        toast.info(message);
      }
    });
  }, []);

  return (
    <AppContainer>
      <Router>
        <Switch>
          <Route path='/live/:room'>
            <RequestProvider>
              <UserDashboard />
            </RequestProvider>
          </Route>
          <Route path='/live'>
            <Welcome />
          </Route>
          <Route path='/portal'>
            <DJProvider>
              <Login />
            </DJProvider>
          </Route>
          <Route path='/join/:partyCode' component={PartyFromURL} />
          <Route exact path='/' component={Home} />
          <Route component={UnknownPage} />
        </Switch>
      </Router>
      <ToastContainer position='bottom-center' autoClose={5000} />
    </AppContainer>
  );
}

export default App;

const AppContainer = styled.div`
  min-height: 100vh;
  height: 100%;
  background: #4e4e50;
`;
