import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components/macro';

import wsClient from '../utils/WebSocketsWrapper';
import { getItem, setItem } from '../utils/StorageUtils';
import { clearTokens, decodeToken, userStatus } from '../utils/TokenUtils';
import { UserTypes } from '../Types/Globals';

const Home = () => {
  const history = useHistory();
  const [partyCode, setCode] = useState('');
  const [busy, setBusy] = useState(false);

  useEffect(() => {
    switch (userStatus()) {
      case UserTypes.DJ:
        history.replace('/portal');
        break;
      case UserTypes.GUEST:
        const { party } = decodeToken().guestInfo;
        if (party.rooms.length === 0) {
          toast.error('No rooms available!');
          clearTokens();
        } else {
          // Go to the first room
          history.replace(`/live/${party.rooms[0]}`);
        }
        break;
      default:
        if (getItem('jukeboxParty')) {
          history.replace('/live');
          break;
        }
        wsClient.addEventListener('findParty', ({ party }) => {
          setBusy(false);
          if (party === null) {
            toast.error('No such party exists!');
          } else {
            setItem('jukeboxParty', party);
            history.push('/live');
          }
        });
    }
  }, [history]);

  const toParty = () => {
    setBusy(true);
    wsClient.send('findParty', { partyCode });
  };

  return (
    <>
      <HomeContainer>
        <div>Welcome to Jukebox! Enter the party room below to join!</div>
        <TextInput
          value={partyCode}
          onChange={(e) => setCode(e.target.value)}
          placeholder='4XpQr'
          onKeyPress={(e) => {
            if (e.key === 'Enter') toParty();
          }}
        ></TextInput>
        <Button disabled={busy || partyCode.length !== 6} onClick={toParty}>
          Go to party
        </Button>
      </HomeContainer>
      <UserLogin>
        <Button onClick={() => history.push('/portal')}>Portal</Button>
      </UserLogin>
    </>
  );
};

export default Home;

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;

  // Center horizontal + vertical
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 250px;
  color: white;
`;

const TextInput = styled.input`
  height: 30px;
  font-size: 14px;
  outline: none;
  border: none;
  padding: 2px 10px;
  margin: 10px 0;
`;

const Button = styled.button`
  background: #c3073f;
  border: none;
  padding: 15px 30px;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  align-self: center;
  width: 100%;

  &:disabled {
    background-color: silver;
    color: dimgray;
    cursor: not-allowed;
  }
`;

const UserLogin = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;

  button {
    padding: 8px 15px;
    font-size: 12px;
  }
`;
