import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import QueueItem from './QueueItem';
import { RequestContext } from '../contexts/RequestsContext';

function UserQueue() {
  const { state } = useContext(RequestContext);
  const { room } = useParams<{ room: string }>();

  return (
    <TableContainer>
      {state.rooms[room]?.length > 0 ? (
        state.rooms[room].map((song, i) => <QueueItem song={song} key={i} />)
      ) : (
        // TODO: Add the overlay here
        <div id='empty'>Search & add songs from the search tab</div>
      )}
    </TableContainer>
  );
}

export default UserQueue;

const TableContainer = styled.div`
  overflow-x: auto;

  #empty {
    text-align: center;
    color: white;
    padding: 10px;
    font-size: 16px;
  }
`;
