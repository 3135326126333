import React from 'react';
import styled from 'styled-components/macro';

import ResultItem from './ResultItem';

import { SearchStates } from '../Types/Globals';

type Props = {
  searchState: SearchStates;
  data: any[];
};

function SearchResults({ searchState, data }: Props) {
  return (
    <>
      {searchState === SearchStates.COMPLETED && (
        <ResultsContainer>
          {data.length > 0 ? (
            data.map((song, i) => <ResultItem song={song} key={i} />)
          ) : (
            <div id='empty'>No results!</div>
          )}
        </ResultsContainer>
      )}
    </>
  );
}

export default SearchResults;

const ResultsContainer = styled.div`
  overflow-x: auto;

  #empty {
    text-align: center;
    color: white;
    padding: 10px;
    font-size: 16px;
  }
`;
