import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useSpring, animated } from 'react-spring';
import { useDrag } from 'react-use-gesture';

import IconButton from '@material-ui/core/IconButton';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import LoopIcon from '@material-ui/icons/Loop';

import { RequestContext, RequestActions } from '../contexts/RequestsContext';
import { SongProps } from '../Types/Globals';

type Props = {
  song: SongProps;
};

const ACTIVATION_SPACE = 80;

function QueueItem({ song: { songID, imgURL, track, artist, status } }: Props) {
  const { room } = useParams<{ room: string }>();
  const { dispatch } = useContext(RequestContext);
  const [{ x }, set] = useSpring(() => ({
    x: 0,
    config: { mass: 1, tension: 120, friction: 14 },
  }));

  const bind = useDrag(
    ({ down, movement: [x] }) => {
      if (down) {
        set({ x: x });
      } else {
        set({ x: 0 });
        if (x > ACTIVATION_SPACE) {
          dispatch({ type: RequestActions.RemoveSong, payload: { room, songID, track, artist } });
        } else if (-x > ACTIVATION_SPACE) {
          dispatch({
            type: RequestActions.RerequestSong,
            payload: { room, songID, track, artist },
          });
        }
      }
    },
    {
      axis: 'x',
    }
  );

  return (
    <ItemContainer {...bind()}>
      <ActionsContainer>
        <animated.div
          style={{
            background: x.interpolate((x) =>
              Math.abs(parseFloat(`${x}`)) > ACTIVATION_SPACE ? 'red' : 'grey'
            ),
            padding: x.interpolate((x) => `0 ${parseFloat(`${x}`) / 2}px`),
          }}
        >
          <IconButton className='action'>
            <RemoveCircleOutlineIcon fontSize='large' />
          </IconButton>
        </animated.div>
        <animated.div
          style={{
            background: x.interpolate((x) =>
              Math.abs(parseFloat(`${x}`)) > ACTIVATION_SPACE ? 'blue' : 'grey'
            ),
            padding: x.interpolate((x) => `0 ${-parseFloat(`${x}`) / 2}px`),
          }}
        >
          <IconButton className='action'>
            <LoopIcon fontSize='large' />
          </IconButton>
        </animated.div>
      </ActionsContainer>
      <SongContainer
        style={{
          transform: x.interpolate((x) => `translate3d(${x}px, 0, 0)`),
          touchAction: 'pan-y',
        }}
      >
        <img src={imgURL} alt='Track' />
        <SongDetails>
          <div>{track}</div>
          <div>{artist}</div>
        </SongDetails>
        <SongContext>
          <div>{status}</div>
        </SongContext>
      </SongContainer>
    </ItemContainer>
  );
}

export default QueueItem;

const ItemContainer = styled(animated.div)`
  width: calc(100% - 40px);
  margin: 10px 20px;
  overflow-x: auto;
  height: 64px;
  position: relative;
  overflow: hidden;

  display: flex;
`;

const SongContainer = styled(animated.div)`
  background: #c3073f;
  position: absolute;
  width: 100%;
  height: 64px;

  font-weight: 600;

  display: flex;
  flex-direction: row;
`;

const SongDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 5px 10px;
  flex: 1;
`;

const SongContext = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 5px;

  div {
    display: flex;
    flex-direction: row;
  }
`;

const ActionsContainer = styled.div`
  width: calc(100% + 25px);
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 -12.5px;

  .action {
    width: 25px;
  }

  div {
    display: flex;
  }
`;
