import React, { useContext } from 'react';
import styled from 'styled-components/macro';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import { DJActions, DJContext } from '../../contexts/DJContext';

function Profile() {
  const { state, dispatch } = useContext(DJContext);

  const changeParty = (partyIdx: any) => {
    dispatch({ type: DJActions.ChangeParty, payload: { partyIdx } });
  };

  return (
    <ProfileContainer>
      <div>
        <InputLabel>Party</InputLabel>
        <Select value={state.currentParty} onChange={(e) => changeParty(e.target.value)}>
          {state.parties.map((party, i) => (
            <MenuItem key={`party${i}`} value={i}>
              {party.name}
            </MenuItem>
          ))}
        </Select>
      </div>
    </ProfileContainer>
  );
}

export default Profile;

const ProfileContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  > div:nth-child(even) {
    min-height: calc(100vh - 64px - 48px - 20px);
    margin: 10px;
  }
`;
