export const getItem = (key: string) => {
  const item = localStorage.getItem(key);
  if (item) {
    try {
      return JSON.parse(item);
    } catch (e) {
      return item;
    }
  }
  return null;
};

export const setItem = (key: string, payload: any) => {
  try {
    let item = payload;
    if (typeof payload !== 'string') {
      item = JSON.stringify(payload);
    }
    localStorage.setItem(key, item);
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const clearItem = (key: string) => {
  localStorage.removeItem(key);
};
